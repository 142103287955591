import React from 'react';
import "../CSS/Footer.css";
import { Link } from 'react-router-dom';
import { GrFacebookOption } from 'react-icons/gr';
import { FaLinkedinIn } from 'react-icons/fa';
import { AiFillInstagram } from 'react-icons/ai';
import { AiOutlineTwitter } from 'react-icons/ai';
import { GrMail } from 'react-icons/gr';
import { FaMapMarkedAlt, FaPhoneSquareAlt } from 'react-icons/fa';
import { BsFacebook } from 'react-icons/bs';
import { IoIosCall, IoLogoWhatsapp } from 'react-icons/io';
import { RiSendPlaneFill } from 'react-icons/ri';

import FooterLogo from "../Images/Icons/11.png";


const Footer = () => {
    return (
        <>
            {/* <!-- ===================================== Footer Section ====================== --> */}
            <div class="footer-basic">
                <footer className='Footer'>
                    <div className='row'>
                        <div className='col-md-4 order-lg-1 order-2'>
                            <div className='Footer-col-1'>
                                <div className='Acting-logo-con'>
                                    <h4>Opening Hours</h4>
                                    <p>Daily</p>
                                    <p>9 am to 10 pm</p>
                                    <p>24 * 7 Services</p>
                                </div>
                                <div className='Footer-Info'>
                                    <p>
                                        Finding the top multispeciality hospital in Titwala involves considering services, medical experts, facilities, and patient feedback. Research, reviews, and local recommendations can help pinpoint the right choice for your healthcare needs.</p>
                                </div>

                                <div className='Footer-Social'>
                                    <div className='Footer_Social-Media'>
                                        <span><a href='https://www.instagram.com/shreekrishnahospital3/' target="_blank"><AiFillInstagram /></a></span>
                                        <span><a href='https://www.facebook.com/profile.php?id=100069060434903' target="_blank"><BsFacebook /></a></span>
                                        <span><a href='https://wa.me/9209199949?text=' target="_blank"><IoLogoWhatsapp /></a></span>
                                        <span><a href='tel:+91 9209199949' target="_blank"><FaPhoneSquareAlt /></a></span>
                                        <span><a href='mailto:shreekrishnahospital.co@gmail.com' target="_blank"><GrMail /></a></span>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 order-lg-2 order-3'>
                            {/* <div className='Footer-col-2'>

                            </div> */}
                            <div className='Footer-Main-Image'>
                                <div className='FooterImageCon'>
                                    <div className='FooterLogo'>
                                        <img src={FooterLogo} alt="" />
                                    </div>
                                </div>
                                <ul class="list-inline">
                                    <li class="list-inline-item"><Link to="/">Home</Link></li>
                                    <li class="list-inline-item"><Link to="/Testimonial">Testimonial</Link></li>
                                    <li class="list-inline-item"><Link to="/AboutUs">About Us </Link></li>
                                    <li class="list-inline-item"><Link to="/ContactUs">Contact Us</Link></li>
                                </ul>

                                <p class="copyright">© Copyright Shree Krishna Hospital . All Rights Reserved</p>
                                <p class="copyright"><a href="https://skdm.in/">Designed by <strong style={{ color: "var(---TheamGoldColor)" }}>Shree Krishna Digital Marketing</strong></a></p>
                            </div>
                        </div>
                        <div className='col-md-4 order-lg-3 order-1'>
                            <div className='Footer-col-3'>
                                <div className='FooterContact'>
                                    <h4>Contact Us</h4>
                                </div>

                                <div className='Contact-Details'>
                                    <div className='Contact-Info'>
                                        <div className='icon'>
                                            <FaMapMarkedAlt />
                                        </div>
                                        <div className='FooterText'>
                                            <h4>Shree Krishna multi-speciality hospital above AXIS Bank , regency sarvam titwala east - 421605</h4>
                                        </div>

                                    </div>
                                </div>

                                <div className='Contact-Details'>
                                    <div className='Contact-Info'>
                                        <div className='icon'>
                                            <IoIosCall />
                                        </div>
                                        <div className='FooterText'>
                                            <h4><a href='tel:+91 9209199949'>+91 92091 99949</a></h4>
                                        </div>

                                    </div>
                                </div>

                                <div className='Contact-Details'>
                                    <div className='Contact-Info'>
                                        <div className='icon'>
                                            <RiSendPlaneFill />
                                        </div>
                                        <div className='FooterText'>
                                            <h4><a href='mailto:shreekrishnahospital.co@gmail.com'>shreekrishnahospital.co@gmail.com</a></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    )
}

export default Footer