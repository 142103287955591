import React, { useLayoutEffect } from 'react';
import "../CSS/AboutUsPage.css";
import ABImage1 from '../Images/AboutUS/about-1.jpg';
import ABImage2 from '../Images/AboutUS/about-2.jpg';
import ABImage3 from '../Images/AboutUS/about-3.jpg';
import { Link } from 'react-router-dom';

// icons 

import HealingHands from "../Images/Icons/hands.png";
import ExperienceDoctors from "../Images/Icons/doctor.png";
import AdvancedHealthcare from "../Images/Icons/handshake.png";
import Pharmacies from "../Images/Icons/medical-symbol.png";
import Ambulance from "../Images/Icons/ambulance.png"
// AboutUs Section 2 

import WorkerComp from "../Images/Worker Comp.jpg";
import SurgeryCenter from "../Images/Surgery Center.jpg";
import PhysicalTherapy from "../Images/Physical Therapy.jpg";
import WelcomeSectionImage from "../Images/Welcome Section.jpg";

import { MdDoubleArrow } from 'react-icons/md';

import DoctorI1 from "../Images/Doctor Images/Pallavi Tari.jpeg";
import DoctorI2 from "../Images/Doctor Images/Chetan Patil.jpeg";
import DoctorI3 from "../Images/Doctor Images/Archana Patil.jpeg";

////////////////////////////// Our Services /////////////////////////////
import DepartmentData from "../Component/DepartmentData";

import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import Footer from '../Component/Footer';



const AboutUs = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });


  return (
    <>
      {/* ////////////////////////////////////// section 1 ////////////////////////////////// */}

      <div className='AboutUs-banner'>
        {/* <NavBarComponent /> */}
        <div className='AboutUs-banner-text'>
          <h1>AboutUs</h1>
        </div>
      </div>

      {/* ////////////////////////////////////// section 1 ////////////////////////////////// */}

      <section className='AboutUs-Section2'>

        <div className='container'>
          <div className='WhoweAre-section'>
            <div className='row'>
              <div className='col-lg-6 col-md-12'>
                <div className='About-Us-first-Section'>
                  <div className='mr-44'>

                    <div className='row align-items-end'>

                      <div className='col-lg-7 col-md-6'>
                        <Zoom>
                          <div className='who-we-are-img-con'>
                            <div className='who-we-are-img'>
                              <img src={DoctorI2} alt="" />
                            </div>
                          </div>
                        </Zoom>
                      </div>

                      <div className='col-lg-5 col-md-6'>
                        <div className='who-we-are-img-2'>
                          <Zoom cascade><h3>Connect With <span> Shree </span> Krishna Hospital</h3></Zoom>
                          <Zoom>
                            <img src={DoctorI1} alt="" />
                          </Zoom>
                        </div>
                      </div>

                      <div className='col-lg-12'>
                        <Zoom>
                          <div className='who-we-are-img-3'>
                            <img src={ABImage3} alt="" />
                          </div>
                        </Zoom>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-12'>
                <div class="ml-44">
                  <div class="who-we-are-content">
                    <span class="top-title">
                      <Slide top cascade>
                        WHO WE ARE
                      </Slide>
                    </span>
                    <h2>We have been providing services to patients for over 7 years in Titwala</h2>
                    {/* <p>Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Donec sollicitudin molestie malesuada. Nulla quis lorem ut libero malesuada feugiat.</p> */}
                  </div>

                  <div class="row card-details">
                    <div class="col-lg-6 col-sm-6">
                      {/* <Zoom> */}
                      <div class="single-who-we-are">
                        <div class="flaticon-hands">
                          <img src={HealingHands} alt="" />
                        </div>
                        <h3>1K+ Healing Hands</h3>
                        <p>The ambience of the hospital is suited to enhance healing.</p>
                      </div>
                      {/* </Zoom> */}
                    </div>
                    <div class="col-lg-6 col-sm-6">
                      {/* <Zoom> */}
                      <div class="single-who-we-are">
                        <div class="flaticon-hands">
                          <img src={ExperienceDoctors} alt="" />
                        </div>
                        <h3>Experienced Doctors</h3>
                        <p>Service of expert and experienced doctor is made available in different medical departments of the hospital.</p>
                      </div>
                      {/* </Zoom> */}
                    </div>
                  </div>
                  <div className='row'>
                    <div class="col-lg-6 col-sm-6">
                      {/* <Zoom> */}
                      <div class="single-who-we-are">
                        <div class="flaticon-hands">
                          <img src={AdvancedHealthcare} alt="" />
                        </div>
                        <h3>Advanced Healthcare</h3>
                        <p>cutting-edge technology, excellent pre and post -operative care and a state-of-the-art infrastructure provide the best of diagnosis and treatment to patients.</p>
                      </div>
                      {/* </Zoom> */}
                    </div>
                    <div class="col-lg-6 col-sm-6">
                      {/* <Zoom> */}
                      <div class="single-who-we-are">
                        <div class="flaticon-hands">
                          <img src={Pharmacies} alt="" />
                        </div>
                        <h3>24/7 Pharmacy</h3>
                        <p>Accessible Pharmacy Services to Better Serve Covered Entities, Patients, and Community.</p>
                      </div>
                      {/* </Zoom> */}
                    </div>
                  </div>
                  <div className='Ambulance-Service'>
                    <div class="single-who-we-are">
                      <div class="flaticon-hands">
                        <img src={Ambulance} alt="" />
                      </div>
                      <h3>24/7 Ambulance Service</h3>
                      <p>Service of expert and experienced doctor is made available in different medical departments of the hospital.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
      {/* /////////////////////////////////// our-Services ///////////////////////////////////// */}

      < section className='our-Services' >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='card-1'>
                <h2>Our Department</h2>
                <hr></hr>
                <h4>modern equipment and specialist</h4>
                {/* <a href="#"><span>MORE</span></a> */}
              </div>
            </div>
            {
              DepartmentData.map((ele) => {
                const { DepartmentIcon, DepartmentName, DepartmentSortDes } = ele
                return (
                  <>
                    <div className='col-lg-4'>
                      <div className='card-2'>
                        <div className='hospital-logo'>
                          {DepartmentIcon}
                        </div>
                        <div className='card-2-material'>
                          <h3>{DepartmentName}</h3>
                          <p>{DepartmentSortDes}</p>
                        </div>
                      </div>
                    </div>
                  </>
                )
              })
            }
          </div>
        </div>
      </section >

      {/* ////////////////////////// Section 3 ////////////////////////////// */}

      {/* ////////////////////////// Section 7 ////////////////////////////// */}


      <section className='HomeSection7'>
        <div className='HomeSection7-Doctor'>
          <div className='container'>
            <div className='HomeSection7-team'>
              <div className='row'>
                <div className='col-md-8'>
                  <div className='HS4-Team-text'>
                    <div className='HS4-Team-info'>

                      <h2>Our Professional Team</h2>

                      <p>Meet the doctors</p>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                </div>
              </div>
            </div>
            <div className='HomeSection7-team-con'>
              <div className='row'>
                <div className='col-md-4' style={{ padding: "0px", margin: "0px" }}>
                  <div className='Team-Card-Body'>

                    <div className='Team-Card-Image'>
                      <img src={DoctorI2} alt="" />
                    </div>

                    <div className='Team-Crad-Info-con'>
                      <div className='Team-Card-Info'>
                        <h6>Gynaecologist & Maternity care </h6>
                        <h2>Dr. Chetan Patil</h2>
                        <p>M.B.B.S., DGO, DNB(MUM)</p>


                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-4 res-big-doctor' style={{ padding: "0px", margin: "0px" }}>
                  <div className='Team-Card-Body'>
                    <div className='Team-Crad-Info-con'>
                      <div className='Team-Card-Info'>
                        <h6>Paediatric</h6>
                        <h2>Dr. Pallavi Tari</h2>
                        <p>M.B.B.S., DCH, PGPN</p>
                      </div>
                    </div>

                    <div className='Team-Card-Image'>
                      <img src={DoctorI1} alt="" />
                    </div>

                  </div>
                </div>

                <div className='col-md-4' style={{ padding: "0px", margin: "0px" }}>
                  <div className='Team-Card-Body'>

                    <div className='Team-Card-Image'>
                      <img src={DoctorI3} alt="" />
                    </div>

                    <div className='Team-Crad-Info-con'>
                      <div className='Team-Card-Info'>
                        <h6>Dermatologist</h6>
                        <h2>Dr. Archana Patil</h2>
                        <p>M.B.B.S., DNB, Skin (Mum)</p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default AboutUs;
