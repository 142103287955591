import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Servicesdrop from '../Component/Servicesdrop';
import HeadOfClinic from "../Images/Doctor Images/head-of-clinic.jpg";
import '../CSS/ServicesInfo.css';
import Footer from "../Component/Footer";
const ServicesInfo = () => {


  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });


  return (
    <>
      {/* ////////////////////////////////////// section 1 ////////////////////////////////// */}

      <div className='ServicesInfo-banner'>
        <div className='ServicesInfo-banner-text'>
          {/* <h1>{Data.ServiceName}</h1> */}
        </div>
      </div>

      {/* ////////////////////////////////////// section 2 ////////////////////////////////// */}

      <div className='ServicesInfo-Section2'>
        <div className='container'>
          <div className='SIS-Data'>
            <div className='row'>
              <div className='col-md-8'>
                <div className='ServiesDetails-con'>
                  <div className='ServiesInfoName'>
                    <h2>
                    {/* {Data.ServiceName} */}
                    </h2>
                    <p>
                     {/* {Data.ShortDes} */}
                    </p>
                  </div>
                  <div className='ServicesInfoImage-con'>
                    <div className='ServicesInfoImage'>
                      {/* <img src={Data.ServicesImage} alt="" /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='SInfo-DocotrDetails'>
                  <div className='ServicesInfo-head'>
                    <h4>The Heart Of Clinic</h4>
                    <hr></hr>
                  </div>
                  <div className='ServicesInfo-Doctor'>
                    <div className='DoctorImage'>
                      <img src={HeadOfClinic} alt="" />
                    </div>
                    <div className='DoctorInfo'>
                      <div className='SInac'>
                        <h4>Dr. Jonathan Barnes</h4>
                        <p>Chief Medical Officer, Founder</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='SInfo-TimeTabel-con'>
                  <div className='SInfo_TimeTabel'>
                    <h4>Working Time</h4>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Esse, labore?</p>
                    <h2>Daily	-	9:00 AM - 10:00 PM</h2>
                    <h3>24 * 7 Services</h3>
                    <h6>Emergency Service</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ////////////////////////////////////// section 3 //////////////////////////////////

      <section className='ServiceInfo-Section3'>
        <div className='container'>
          <div className='ServiceInfo-container'>
            <div className='row'>
              {
                Data.SubServices.map((ele) => {
                  const { SubServicesName, SubSerShortDes,SImages, id } = ele
                  return (
                    <>
                      <div className='col-md-4'>
                        <div className='SIS-Card-Body'>
                          <div className='SIS-card-Con'>
                            <div className='SIS-card-Image-con'>
                              <img src={SImages} alt="" />
                            </div>
                            <div className='SIS-Card-Heading'>
                              <h3>{SubServicesName}</h3>
                              <p>{SubSerShortDes}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })
              }

            </div>
          </div>
        </div>

      </section> */}

      <Footer/>
    </>
  )
}

export default ServicesInfo