import Image01 from "../Images/OrthoPedic Surgeon/Bone Plating And Nailing.jpg";
import Image02 from "../Images/Maternity Hospital/Hysterectomy c.jpg";

const VideoGalleryData = [
    {
        id: 1,
        Category:"Hospital",
        EImage: Image01,
    },
    {
        id: 2,
        Category:"Testimonial",
        EImage: Image02,
    },
    {
        id: 3,
        Category:"Hospital",
        EImage: Image02,
    },
    {
        id: 4,
        Category:"Staff",
        EImage: Image02,
    },
    {
        id: 5,
        Category:"Treatment",
        EImage: Image02,
    },
    {
        id: 6,
        Category:"Hospital",
        EImage: Image02,
    },
];

export default VideoGalleryData;
