import React from 'react';
import "../CSS/Item.css";
import Zoom from 'react-reveal/Zoom';
const Items = ({ data }) => {
    return (
        <>
            <div className='row'>
                {
                    data.map((value) => {
                        const { id, EImage, EventName } = value

                        return (
                            <>
                                <Zoom>
                                    <div className="col-sm-4 my-3" key={id}>
                                        <div className="card bg-light text-center">
                                            <img src={EImage} />
                                            <div className='Card-text'>
                                                <h2>{EventName}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </Zoom>
                            </>
                        )
                    })
                }

            </div>
        </>
    )
}

export default Items