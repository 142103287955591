
import "../CSS/NavBar.css"

import { useState } from "react";

import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import { AiOutlineMail } from 'react-icons/ai';
import { GrFacebookOption, GrInstagram, GrLinkedinOption, GrTwitter } from 'react-icons/gr';
import { FiPhoneCall, FiClock } from 'react-icons/fi';

import Logo from "../Images/Icons/11.png";
import SpecialitiesdropData from "./SpecialitiesdropData";
import Servicedrop from "./Servicesdrop";




const NavBar = () => {

    // Change Nav color  when scrolling

    const [color, setColor] = useState(false)

    const changeColor = () => {
        if (window.scrollY >= 78) {
            setColor(true)
        } else {
            setColor(false)
        }
    }

    window.addEventListener('scroll', changeColor)

    return (
        <>
            <div className="nav-bar-component">
                {/* 

                <section className="header">
                    <div className="container">
                        <div className="header-material">
                            <div className="logo">
                                <div className="Logo-con">
                                    <img src={Logo} alt="" />
                                </div>
                            </div>

                            <div className="Email_Support">
                                <div className="Email-logo">
                                    <AiOutlineMail />
                                </div>
                                <div className="Email-text">
                                    <span>
                                        Email Support
                                    </span>
                                    <h5>
                                        demo@gmail.com
                                    </h5>
                                </div>
                            </div>

                            <div className="Call_Support">
                                <div className="Call-logo">
                                    <FiPhoneCall />
                                </div>
                                <div className="Call-text">
                                    <span>
                                        Call Support
                                    </span>
                                    <h5>
                                        0000-000-000
                                    </h5>
                                </div>
                            </div>

                            <div className="Workinghr">
                                <div className="Time-logo">
                                    <FiClock />
                                </div>
                                <div className="Time-Text">
                                    <span>
                                        Working Hours
                                    </span>
                                    <h5>
                                        Open 24/7
                                    </h5>
                                </div>
                            </div>

                            <div className="Appointment">
                                <button>
                                    Appointment
                                </button>
                            </div>

                        </div>
                    </div>
                </section> */}
                <section className="NavSection">
                    {/* <div className="container"> */}
                    <Navbar collapseOnSelect expand="lg" variant="dark" className={color ? "NavBar NavBar-bg" : "NavBar"}>
                        <Container>
                            <Navbar.Brand href="#home">
                                <div className="ResNavLogo">
                                    <img src={Logo} alt="" />
                                </div>
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-center">
                                <Nav>
                                    <Nav.Link href="#home"> <Link to="/">Home</Link> </Nav.Link>
                                    {/* <Nav.Link href="#Department"> <Link to="/Services">Services</Link> </Nav.Link> */}
                                    <Nav.Link href="#Department"> <Link to="/Doctors">Doctors</Link> </Nav.Link>
                                    <NavDropdown title="Specialities" id="collasible-nav-dropdown" className="Specialitiesdrop">
                                        <div className="row">
                                            {
                                                SpecialitiesdropData.map((elem) => {
                                                    const { Slugs, SpecialitiesDropName } = elem
                                                    return (
                                                        <>
                                                            <div className="col-md-4 col-lg-3">
                                                                <NavDropdown.Item href="#action/3.1">
                                                                    <Link to={`${Slugs}`}>{SpecialitiesDropName}</Link>
                                                                </NavDropdown.Item>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    </NavDropdown>
                                    {/* <NavDropdown title="Media" id="collasible-nav-dropdown">
                                    <NavDropdown.Item href="#action/3.1">
                                        <Link to="/ImageGallery">Image Gallery</Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.2">
                                        <Link to="/VideoGallery">Video Gallery</Link>
                                    </NavDropdown.Item>
                                </NavDropdown> */}

                                    <NavDropdown title="Services" id="collasible-nav-dropdown" className="Specialitiesdrop Seconddrop">
                                        <div className="row">
                                            {
                                                Servicedrop.map((elem) => {
                                                    const { Slugs, Servicesdropname } = elem
                                                    return (
                                                        <>
                                                            <div className="col-md-3">
                                                                <NavDropdown.Item href="#action/3.1">
                                                                    <Link to={`${Slugs}`}>{Servicesdropname}</Link>
                                                                </NavDropdown.Item>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    </NavDropdown>
                                    <Nav.Link href="#Doctor's"> <Link to="/Testimonial">Testimonial</Link> </Nav.Link>
                                    <Nav.Link href="#About us"> <Link to="/AboutUs">About Us</Link> </Nav.Link>
                                    <Nav.Link href="#Contact us"> <Link to="/ContactUs">Contact Us</Link> </Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                            <Nav className="Social-media-icons">
                                <div className="Appointment">
                                    <a href='tel:+91 9209199949'>
                                        <button>
                                            Contact Us
                                        </button>
                                    </a>
                                </div>
                            </Nav>
                        </Container>
                    </Navbar>
                    {/* </div> */}
                </section>

            </div>
        </>
    )

}

export default NavBar;