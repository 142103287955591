import ICUNICU from "../Images/Specialities/ICU & NICU.jpg";
import AccidentTruma from "../Images/Specialities/Accident & Truma.jpg";
import InternalMedicine from "../Images/Specialities/Internal Medicine.jpg";
import DiabeticHypertensionClinic from "../Images/Specialities/Diabetic & Hypertension Clinic.jpg";
import GeneralSurgery from "../Images/Specialities/General Surgery.jpg";
import DermatologySkinClinic from "../Images/Specialities/Dermatology Skin Clinic.webp";
// import HivClinic from "../Images/Specialities/Hiv Clinic.png";
import NeurologyClinic from "../Images/Specialities/Neurology Clinic.jpg";
import UrologySurgery from "../Images/Specialities/Urology Surgery.jpeg";
import NephrologyClinic from "../Images/Specialities/Nephrology Clinic.jpg";
import CardiologyClinic from "../Images/Specialities/Cardiology Clinic.jpg";
import PaediatricSurgery from "../Images/Specialities/Paediatric Surgery.jpg";
import OrthoSurgery from "../Images/Specialities/Ortho Surgery.jpg";
// import CancerCareSurgery from "../Images/Specialities/Cancer Care & Surgery.webp";
import EntSurgery from "../Images/Specialities/Ent Surgery.jpeg";
import GynaecologyMaternityCare from "../Images/Specialities/General Surgery.jpg";
import AncCheckUp from "../Images/Specialities/Anc Check Up.jpg";
import PaediatricClinic from "../Images/Specialities/Paediatric Clinic.jpg";
// import Psychiatry from "../Images/Specialities/Psychiatry.jpg";
// import Dialysis from "../Images/Specialities/Dialysis.jpg";
import Neurosurgery from "../Images/Specialities/Neurosurgery.jpg";
import Physiotherapy from "../Images/Specialities/Physiotherapy.jpg";
import Dietician from "../Images/Specialities/Dietician.jpg";

const SpecialitiesdropData = [
    {
        id: 1,
        Slugs:"/icu-nicu",
        SpecialitiesDropName: "ICU & NICU",
        SpecialitiesImage: ICUNICU,
        HeadDes01:"Using advanced telemedicine technology, a team of specially trained eICU nurses and critical care physicians (physicians with additional critical care training) will receive critical care patients from the Remote Support Centre at Memorial Massachusetts Medical Centre. is monitored 24/7. The team uses advanced technology and tools to detect subtle changes in a patient's vital signs before they are visible and works with patient care teams to resolve issues quickly. ",
        HeadDes02:"The Intensive Care Unit (ICU) is a specialised ward that allows for the care and monitoring of critically ill patients.",
        HeadDes03:"staffed by specially trained medical professionals and equipped with advanced monitoring equipment.",
        HeadDes04:"The Intensive Care Unit is also called the Intensive Care Unit (CCU) or the Intensive Care Unit (ITU).",
        PointHeadTitle01:"Neonatal care and ICU in Titwala",
        PointHead01:"A patient may require ICU care for the following reasons:",
        Point01:"Very high or very low blood pressure",
        Point02:"Heart rhythm problems",
        Point03:"Heart attack ",
        Point04:"Stroke",
        Point05:"Breathing problems ",
        Point06:"Pneumonia ",
        Point07:"Drug or alcohol overdose or withdrawal",
        Point08:"Recovery from major surgery",
        PointHead001:"NICU",
        Point001:"Neonatal and ICU care in Titwala",
        Point002:"The birth of a baby is a wonderful and very complicated process. Many physical and emotional changes occur in both mother and child.",
        Point003:"Babies have to make many physical adjustments to live outside their mother's body. Moving out of the womb means that the baby no longer depends on the mother's blood supply and placenta for vital bodily functions. A neonatal intensive care unit (NICU) is a hospital intensive care unit that specialises in the care of sick and premature babies. Babies at birth are dependent on their mothers functions. These include breathing, eating, waste disposal, and immune protection. When a baby leaves the womb, the body's systems need to change. Our NICUs are equipped with specialist doctors, nurses, and other professionals to care for sick and premature babies. Applying the latest developments and best practises in the NICU, we founded a NICU designed to support family-centred care, improve outcomes, and advance research in the NICU.",
    },
    {
        id: 2,
        Slugs:"/accident-trauma",
        SpecialitiesDropName: "Accident & Trauma",
        SpecialitiesImage: AccidentTruma,
        HeadDes01:"In our accident and trauma centre in Titwala, Mumbai, The trauma centre is staffed 24 hours a day, 7 days a week, with highly qualified doctors, specialists, critical care nurses, and support staff to address the most acute medical needs. Trauma centres often provide burn care to people suffering from mild to severe burns.",
        Ques01:"What is a Trauma Centre?",
        Ans001:"Trauma Centres are specialty hospitals characterised by the ready availability of personnel, equipment, and services to treat the most serious injuries.",
        Ans002:"Any injury to the body is considered trauma. It is often called the neglected disease of the 21st century. Common causes of trauma include falls, traffic accidents, assaults, and gunshots. Trauma doesn't just happen at critical moments spent at the scene of an accident or in the emergency room. In our accident and trauma centre in Titwala,  Mumbai, Our comprehensive trauma team will be your co-pilot throughout your healing journey, from the moment you are injured until you are back on track.",
        Point01:"Our goal is to get the injured patient on life support as quickly as possible. As a Tier 1 trauma centre, we treat over 1,500 adults each year and have a Critical Care Surgeon on staff 24/7. Some of the most common injuries we treat include car accidents, bicycles being run over by cars, and elderly people who have had serious falls leading to head injuries and fractures.",
        Point02:"We have special expertise and resources to treat people with head injuries, such as resources in the Neurology ICU at Shree Krishna Hospital, which treats injuries and diseases related to the brain and spine. Masu. ",
        Point03:"Rehabilitation is also part of care. Physiotherapists at Rusk Rehabilitation help people move as quickly as possible, depending on the severity of their injury. After patients are discharged from the hospital, we continue to monitor their recovery in weekly trauma clinics that specialise in the aftercare of people recovering from injuries.",
    },
    {
        id: 3,
        Slugs:"/internal-medicine",
        SpecialitiesDropName: "Internal Medicine",
        SpecialitiesImage: InternalMedicine,
        HeadDes01: "If you are looking for information about internists and the specialties of internal medicine, you are in the right place. Internal medicine is a branch of medicine that focuses on the prevention, diagnosis, and treatment of various diseases in adults.",
        HeadDes02: "Our doctors specialize in the prevention, diagnosis, and treatment of diseases that primarily affect adults. As your primary care physician, we are here to be your trusted healthcare partner and to build a long-term relationship with you throughout adulthood. ",
        HeadDes03: "We specialize in adult medicine and are specially trained to solve diagnostic problems, manage serious long-term illnesses, and assist patients with multiple complex chronic conditions.",
        Ques01:"What is internal medicine? ",
        Ans001:"Physicians are professionals who apply scientific knowledge and clinical expertise to the diagnosis, treatment, and compassionate care of adults, from wellness to complex illnesses. Physicians in internal medicine, also known as internists, are experts in complexity. They specialize in adult medicine and are specially trained to solve diagnostic problems, treat serious long-term illnesses, and care for patients with multiple complex chronic conditions. They provide comprehensive and long-term patient care. Furthermore, they develop lifelong relationships with their adult patients, and their recommendations are based on each patient's unique circumstances. ",
        Ans002:"An expert in internal medicine explains. Their deep training and knowledge of the entire human body and its organ systems give them a unique perspective on how everything works together. They analyze, explore, and connect multiple datasets to find solutions for optimal health outcomes. Physicians of internal medicine are called 'physicians' because they are often referred to by other medical professionals because of their ability to make connections and solve problems. Our expertise has made our indispensable to both patients and healthcare professionals.",
    },
    {
        id: 12,
        Slugs:"/paediatric-surgery",
        SpecialitiesDropName: "Paediatric Surgery",
        SpecialitiesImage: PaediatricSurgery,
        HeadDes01: "Pediatric Surgery Hospital in titwala, provides general and specialty surgical services to infants, children and adolescents with a variety of congenital and acquired diseases such as hernias, edema, cryptorchidism, cysts and tumors of the head and neck. ",
        HeadDes02: "Our team listens to you and your child. We will work with you to come up with a treatment plan that is tailored to your child's condition. We are constantly engaged in clinical research to develop new diagnostic and therapeutic strategies.",
        HeadDes03: "Paediatric surgery Hospital in titwala strives to provide first-class clinical care for children, both outpatient and inpatient. Care is designed to help children recover from mundane to highly complex illnesses that occur from conception through adulthood.",
        HeadDes04: "Our pediatric surgeons and care team work in a learning environment that values children. Working with the Pediatric Surgical Hospital in Titwara and highly qualified pediatric anesthesiologists, these teams approach every procedure with special care for pediatric patients. ",
        HeadDes05: " We are committed to continuously educating our patients, families and colleagues, as well as expanding the knowledge of future leaders in our field.",
        HeadDes06: " We support both the basic and clinical exploration of new diagnostic and therapeutic strategies and the joy and fulfillment of discovery itself. We aim to be a team of teachers who are full of their own growth, grateful and rewarded for their health and families. We give you the opportunity to take care of your children and your family.",
        HeadDes07: " At  Pediatric Surgery Hospital in titwala , our team provides care and treatment for infants, children and adolescents with a wide range of common and complex conditions. In our hospital, we introduce various surgical methods including the latest minimally invasive surgery for patients who need surgery. Our team creates an individual care plan for each patient's condition and unique circumstances to ensure the best possible treatment and recovery options.",
    },
    {
        id: 16,
        Slugs:"/Gynaecology-maternity-care",
        SpecialitiesDropName: "Gynaecology & Maternity Care",
        SpecialitiesImage: GynaecologyMaternityCare,
        HeadDes01: "At the Gynecology and Maternity Care Hospital in Mumbai, we've designed family-centered maternal and child health services focused on you, your family, and your newborn. Our facilities and the services we offer can help make your care easier, from prenatal testing and birth assistance to postnatal and neonatal care.",
        HeadDes02: "Our maternal and child health team consists of highly qualified and board-certified obstetricians, neonatologists, and nurses who provide the warm, caring care of a community hospital. It offers.",
        HeadDes03: "When choosing a hospital to deliver a newborn, expectant parents understand that quality care is the most important consideration and seek a hospital that provides the safest environment for the delivery of their newborn.",
        HeadDes04: "Gynecology and maternity care hospital in Mumbai delivers more babies than any other hospital in the county. At a gynecology and maternity care hospital in Mumbai, maternal and child health services are designed with clinical quality in mind. The birth of a baby is a very precious and important moment in a family's life. Even if your due date is far away, you'll be amazed at how quickly time flies.",
        HeadDes05: "We help women and babies achieve optimal health and empower women to have a strong and rewarding childbirth experience. We provide evidence-based care in a safe and welcoming atmosphere, grounded in cultural humility and caregiver diversity. Our care is innovative, clinically superior, and complements a growing body of research on best practices for women and families. Together, we educate and work to develop a new generation of midwives trained to support physiological childbirth and women-centred care. Our practices reflect our values of excellence, compassion, community, and social justice.",
    },
    {
        id: 17,
        Slugs:"/anc-check-up",
        SpecialitiesDropName: "Anc Check Up",
        SpecialitiesImage: AncCheckUp,
        HeadDes01:"Welcome to the best antenatal care hospital in Mumbai",
        HeadDes02:"key points of care for antenatal care or prenatal care",
        HeadDes03:"Anc or prenatal care, is the medical care you receive during pregnancy. At every prenatal visit, your doctor will check on you and your growing baby.",
        HeadDes04:"Call your doctor as soon as you find out you are pregnant to schedule your first test.",
        HeadDes05:"Early and regular prenatal care contributes to a healthy pregnancy and full-term delivery.",
        HeadDes06:"Get all the tests done, even if you're fine.",
        Ques01:"What is prenatal care, and why is it important?",
        Ans001:"Prenatal care is the medical care you receive during pregnancy. At each visit, your doctor will check on you and your growing baby. Call your doctor as soon as you find out you are pregnant for an initial test. And be sure to get tested, even if you feel fine. ",
        Ans002:"Early and regular prenatal care can help you have a healthy pregnancy and deliver a full-term baby. Full-term means that the baby is born between her 39 weeks (one week before her due date) and her 40 weeks and 6 days (one week after her due date). Because the baby is born full term, it has the time it needs to grow and develop inside the womb.",
        EndDes01:"Don't be afraid to discuss personal issues with your provider. Doctors need to know everything about you, so they can give you and your baby the best possible care. She asks a lot of questions about you, your partner, and your family. Your medical information and anything else you share with her will be kept confidential. This means she cannot share them with others without your permission. So don't be afraid to tell her if her partner hurts or scares you, or if it's uncomfortable or embarrassing, like smoking, drinking, using street drugs, or abusing prescription drugs.",

    },
    {
        id: 10,
        Slugs:"/nephrology-clinic",
        SpecialitiesDropName: "Nephrology Clinic",
        SpecialitiesImage: NephrologyClinic,
        HeadDes01:"Nephrology specializes in all aspects of kidney disease, including: renal biopsy, intensive care nephrology, hemodialysis, and peritoneal dialysis. We provide state-of-the-art outpatient clinical care for a wide range of renal diseases, serving patients nationally and internationally. Our faculty has expertise in all areas of nephrology clinical care, including the treatment of acute and chronic kidney disease, glomerulonephritis, cystic disease, kidney stones, metabolic disorders, and kidney transplantation.",
        HeadDes02:"A nephrology clinic in Mumbai has established an excellent specialty department where patients receive the care and individualized attention they need, from the initial appointment to the completion of recommended and selected treatments.",
        HeadDes03:"Hospital is considered to be the best nephrology clinic in Mumbai. This was only possible thanks to the constant support of a dedicated and talented team of nephrologists and kidney transplant physicians and staff. Our doctors treat patients in an excellent manner with patient satisfaction as the top priority, making it the best nephrology clinic in Mumbai. The services and medical facilities available at Mumbai Nephrology are of high quality, and the treatment provided here is entirely results-oriented, as evidenced by the high success rate.",
        PointHead01:"The Nephrology Clinic in Mumbai uses state-of-the-art technology and equipment to treat kidney patients. The main facilities are as follows.",
        Point01:"Dornier lithotripter",
        Point02:"Uroflowmetry",
        Point03:"LED light source",
        Point04:"dedicated dialysis unit",
        Point05:"Hemodialysis for acute and chronic renal failure",
        Point06:"Hemodialysis for drug overdose",
        Point07:"Plasmapheresis for renal and non-renal cases Therapy",
        Point08:"Continuous Rich Amble Atelier Peritoneal Dialysis (CAPD)",
        Point09:"Automated Peritoneal Dialysis (APD)",
        Point10:"Continuous Renal Replacement Therapy (CRRT)",
        Point11:"Nephrology Intensive Care",
        EndDes01:"Use evidence-based guidelines and standard protocols at affordable prices. ,nephrology clinic in Mumbai offers a wide range of state-of-the-art services for patients.",
    },
    {
        id: 4,
        Slugs:"/diabetic-hypertension-clinic",
        SpecialitiesDropName: "Diabetic & Hypertension Clinic",
        SpecialitiesImage: DiabeticHypertensionClinic,
        HeadDes01:"The Diabetes and Hypertension Clinic in Titwala provides the best evidence-based diabetes, hypertension, and endocrine care in a stimulating environment to put patients at ease. People with diabetes are twice as likely to develop high blood pressure as people without diabetes. Left untreated, high blood pressure can lead to heart disease and stroke. In fact, a person with diabetes and high blood pressure is four times more likely to develop heart disease than someone without either condition. About two-thirds of adults with diabetes have a blood pressure of 130/80 mmHg or higher, or take prescription medications for high blood pressure.",
        PointHead01:"Diabetes",
        Point01:"We know a lot about diabetes. It is now a household name. This chronic disease is caused by a congenital or acquired deficiency of insulin production and usually manifests as hyperglycemia, affecting nerves, blood vessels, and many other body systems.",
        Point02:"And with more than 77 million Indians diagnosed with diabetes, effective care and treatment are an urgent need. Under our team, Shree Krishna Hospital offers a unique and personalized treatment plan to help patients manage diabetes and its complications with ease.",
        PointHead001:"Hypertension",
        Point001:"High blood pressure, also called hypertension, has many complications but rarely causes symptoms. Left unchecked, it can damage your internal organs and cardiovascular system, increasing your risk of heart disease, stroke, and death.",
        Point002:"Treating high blood pressure is even more important for a diabetic because he is twice as likely to develop it. Additionally, a person diagnosed with both diabetes and high blood pressure is four times more likely to develop heart disease than he is.",
        Point003:" To manage these risks and provide a better quality of life, our long-term diabetes and hypertension management plan includes",
        Point004:"Strategic Use of Insulin and Diabetes Medications",
        Point005:"Introduction of Lifestyle Changes Through Diet and Physical Activity",
        Point006:"Containment of Unsuitable Habits, Such as Drinking and Smoking",
        Point007:"Addressing other diseases and side effects Caused by Diabetes ",
    },
    {
        id: 5,
        Slugs:"/general-surgery",
        SpecialitiesDropName: "General Surgery",
        SpecialitiesImage: GeneralSurgery,
        HeadDes01:"General surgery in titwala is a surgical specialty focused primarily on disorders of the abdominal wall and its contents, including the esophagus, stomach, small intestine, large intestine, liver, pancreas, gallbladder, appendix, and bile ducts. Our surgeons are all nationally qualified and have many years of experience in treating diseases of these organs.",
        HeadDes02:"At General Surgery Hospital in Titwala our team has diagnostic expertise and consists of highly qualified surgeons with experience in preoperative, postoperative, and postoperative patient care. Our general surgeons provide examination and surgery for diseases of the gastrointestinal tract (esophagus and related organs), abdomen and its contents (liver, spleen, gallbladder, etc.), soft tissues including skin and breast, endocrine system, and colon. Increase. , rectum, etc.",
        HeadDes03:"General surgeons have specialized knowledge and experience related to diagnosis and preoperative, intraoperative, and postoperative management, including management of complications, in the nine major components of surgery. All of these are essential to the training of surgeons in a wide range of disciplines.",
        HeadDes04:"Gastrointestinal tract",
        HeadDes05:"Abdomen and its contents",
        HeadDes06:"Breast, skin, and soft tissue ",
        HeadDes07:"Head and neck (including trauma, vascular disease, endocrine disease, congenital disease, and tumor disease)—especially skin, salivary glands, thyroid, parathyroid, and oral cavity Oncology ",
        HeadDes08:"Vascular Endocrine System, Excluding Intracranial Vascular and Cardiac",
        HeadDes09:"Endocrine System, Including Thyroid, Parathyroid, Adrenal, and Pancreatic Endocrine",
        HeadDes10:"Oncology Surgery Coordination of Cancer Patients through Screening, Surveillance, Adjuvant Surgery, Rehabilitation, and Follow-Up",
        HeadDes11:"Comprehensive treatment of trauma, including musculoskeletal, hand, and head injuries, including multidisciplinary care—responsible for all stages of care of injured patients is an integral part of general surgery.",
        HeadDes12:"Comprehensive care for critically ill patients with underlying medical conditions in the ER, ICU, and trauma/burn wards",

    },
    {
        id: 6,
        Slugs:"/dermatology-skin-clinic",
        SpecialitiesDropName: "Dermatology Skin Clinic",
        SpecialitiesImage: DermatologySkinClinic,
        HeadDes01:"Those seeking answers to skin, mucous membrane, and nail ailments, hair loss, and facial aging can find the help they need at the Dermatology Clinic in titwala , a well-known dermatology clinic. Our dermatologists (adult and pediatric dermatologists, dermatopathologists, skin surgeons) diagnose and treat hundreds of conditions, including rare and genetic conditions. They work closely with you to understand your dermatological needs and find the right answer from the beginning.",
        HeadDes02:"All this expertise in one place means your treatment is discussed as a team, test results are readily available, and appointments are coordinated. What may take weeks or even months elsewhere can usually be completed in a few days at the Titwara Dermatology Clinic.",
        HeadDes03:"Our dermatologists, dermatopathologists and Mohs surgeons also provide resources to complement the treatment you receive from your local doctor.",
        HeadDes04:"Professional skin care for patients of all ages",
        HeadDes05:"An exceptional level of knowledge from professionally trained physicians and certified dermatologists. The focus of our practice is delicate and personal care. At our clinic, we strive to provide you with the latest dermatology treatments in a comfortable environment.",
        HeadDes06:"In the Dermatology clinic in titwala , Our dermatologists treat patients with all types of skin conditions, including moles and rashes, up to the diagnosis of skin cancer.",
        HeadDes07:"Our experienced board-certified dermatologists are able to treat the most complex conditions with a comprehensive, team-based approach",
    },
    {
        id: 8,
        Slugs:"/neurology-clinic",
        SpecialitiesDropName: "Neurology Clinic",
        SpecialitiesImage: NeurologyClinic,
        HeadDes01: "We are known as the best neurosurgery hospital in titwala. This is beautiful if we are leaders in the diagnosis and treatment of all types of neurological disorders. Our nationally and internationally recognized neurologists and neurosurgeons provide specialized care for diseases and disorders of the brain, spinal cord, and nervous system, including stroke, cerebral aneurysms, brain tumors, and degenerative spinal disease. We also have a special neurosurgical intensive care unit and strive to provide the highest level of care for even the most severe brain injuries.",
        PointHeadTitle01:"Our Specialties  in the best neurosurgery hospital in titwala",
        PointTitle01:"Stroke",
        Point01:"We are an accredited comprehensive stroke center providing the highest level of stroke care 24 hours a day, 365 days a year. We are known for providing advanced interventions to treat stroke before it causes permanent damage to the brain.",
        PointTitle02:"Cerebral Aneurysm",
        Point02:"The Cerebral Aneurysm Program provides expert diagnostics and innovative treatments for cerebral aneurysms and other cerebrovascular diseases such as arteriovenous malformations (AVMs).",
        PointTitle03:"Epilepsy/Movement Disorders",
        Point03:"We offer advanced computer-guided surgical techniques to treat movement disorders such as epilepsy, spasticity, and Parkinson's disease.",
        PointTitle04:"Brain Tumors",
        Point04:"Our team specializes in testing and treating all types of brain tumors. Get the best care from neurosurgeons, neuro-oncologists, neuroradiologists, physical therapists, speech-language pathologists, and other specialists.",
        PointTitle05:"Concussion",
        Point05:"Concussion Management Program recognizes the potentially dangerous long-term effects that traumatic brain injury and concussion can cause.",
        PointTitle06:"Alzheimer's Disease/Amnesia",
        Point06:"Our Memory and Aging Clinic diagnoses and treats people with memory impairment, language impairment, cognitive disorders such as Alzheimer's disease, dementia, and related behavioral disorders.",
        PointTitle07:"Multiple Sclerosis (MS)",
        Point07:"Our Clinic provides comprehensive care for people with MS and related conditions. Our MS clinic provides individualized care and treatment to manage your condition and improve your quality of life.",
        PointTitle08:"Spine Surgery",
        Point08:"We specialize in a wide range of non-surgical, minimally invasive, and conventional surgical techniques to treat problems of the back, neck, and spine.",

    },
    {
        id: 9,
        Slugs:"/urology-surgery",
        SpecialitiesDropName: "Urology Surgery",
        SpecialitiesImage: UrologySurgery,
        HeadDes01:"At the urology surgery hospital in titwala,  our experienced team provides specialized urology care in a comfortable and welcoming environment. We offer both surgical and non-surgical treatments for a variety of conditions affecting the colon, prostate and bladder.",
        Ques01:"What is Urology?",
        Ans001:"Urology is one of the most diverse branches of surgery and includes diseases of the kidneys, bladder and prostate, including incontinence, impotence, infertility, cancer and reconstruction of the urogenital tract. A wide range of patients come to the hospital regardless of gender and age, from newborns to older adults.",
        Ans002:" Talking about urological problems can be difficult. Our team at Titwala Urological Surgical Hospital strives to ensure your comfort so that we can provide you with the best care you need. From general urological health to more specific erectile dysfunction solutions, we are committed to improving the quality of life for our patients through innovative treatments.",
        PointHead01:"We treat the following diseases.",
        Point01:"Incontinence",
        Point02:"Impotence or erectile dysfunction",
        Point03:"Kidney stones",
        Point04:"Renal disease",
        Point05:"Kidney disease",
        Point06:"Prostate cancer",
        Point07:"Bladder cancer",
        Point08:"Testicular cancer",
        Point09:"Pelvic floor dysfunction",
        EndDes01:"Our team of urologists use the latest surgical techniques to treat a wide variety of urological diseases. Urology Surgery Hospital in titwala offers minimally invasive and laparoscopic surgery to help patients recover faster and with less pain.",
    },
    {
        id: 13,
        Slugs:"/ortho-surgery",
        SpecialitiesDropName: "Ortho Surgery",
        SpecialitiesImage: OrthoSurgery,
        HeadDes01:"The orthosurgery department in Titwala strives to provide cutting-edge treatments and exceptional compassionate care for patients with orthopaedic and musculoskeletal disorders. We also offer non-surgical general musculoskeletal care for common orthopaedic conditions that are best treated without surgery. In conjunction with the Physiotherapy, rehabilitation, and Primary Care departments, our department has highly qualified non-surgical doctors who specialise in the non-surgical treatment of various diseases of the musculoskeletal system. Common orthopaedic conditions such as osteoarthritis, back pain, sciatica, carpal tunnel syndrome, rotator cuff shoulder pain, knee ligament and meniscus injuries, hallux valgus, tennis elbow, hip impingement syndrome, muscle and tendon injuries, and bone fractures are all common examples. Conservative treatment is successful in many cases of diagnosis, but in some cases, surgery may be needed to restore the quality of life the patient desires.",
        HeadDes02:"Our vision for our orthosurgery hospital in Titwala is to provide the local, national, and international communities with the highest quality orthopaedic care from the most experienced and respected academic orthopaedic surgeons. Our surgical team also works closely with other medical professionals and staff to provide optimal clinical care, thereby achieving the best possible clinical outcome for our patients.",
        HeadDes03:"The goal of orthopaedic surgery is to relieve pain and allow you to return to your favourite activities. However, not all hospitals have the same results. Some things are more reliable than others. The Hospital Reliability Scorecard helps you ask the right questions to find the right hospital for you. Understanding the following data points will help you make the best decision for treatment. Our surgeons continue to push the boundaries of what is possible in musculoskeletal care.",
    },
    {
        id: 15,
        Slugs:"/ent-surgery",
        SpecialitiesDropName: "Ent Surgery",
        SpecialitiesImage: EntSurgery,
        HeadDes01:"At the ENT surgery Hospital in Titwala, Our multidisciplinary team of board-certified otolaryngologists assesses, diagnoses, and treats all areas of ear, nose, and throat disease and offers advanced head and throat treatments. specialises in Committed to providing the highest quality, the doctors at Thitwara Ear, Nose, and Throat Hospital are highly qualified to provide the care you need and state-of-the-art treatments, treating each condition individually.",
        HeadDes02:"Otolaryngology surgery is performed on the head or neck to treat ear, nose, and throat problems. Also called otolaryngological (ENT) surgery. An otolaryngologist is a doctor trained to treat patients with diseases and disorders of the ear, nose, and throat. In some cases, these disorders may require surgery. Learn more about ENT surgery. Otorhinolaryngology focuses on the surgical treatment of diseases of the ear, nose, and throat. This type of surgery is performed by an otolaryngologist, a doctor trained to treat patients with disorders and diseases of the ear, nose, throat, and other structures of the throat and face.",
        HeadDes03:"Ear, nose, and throat surgery helps repair damage or defects in these areas that cause problems such as pain, frequent infections, and trouble breathing. For example, some children need ENT surgery to treat recurring ear infections. Ear, nose, and throat surgery may be needed to remove the tumour (abnormal growth). Depending on the procedure, surgery can be done in a clinic, outpatient facility, or hospital.",
        PointHead01:"Some of the most common ENT disorders that may require ENT surgery include:",
        Point01:"ear problems",
        Point02:"frequent ear infections",
        Point03:"nose problems",
        Point04:"frequent sinus pains and infections.",
        Point05:"throat problems",
        Point06:"vocal cord disorders",
        Point07:"tonsillitis (inflammation of the tonsils, the two tissue pads in the back of the throat)",
        Point08:"difficulty swallowing",
        PointHead001:"Other head and neck problems:",
        Point001:"head or neck cancer",
        Point002:"face damage",
    },
    {
        id: 18,
        Slugs:"/paediatric-clinic",
        SpecialitiesDropName: "Paediatric Clinic",
        SpecialitiesImage: PaediatricClinic,
        HeadDes01:"Pediatric Surgery Hospital in titwala, provides general and specialty surgical services to infants, children, and adolescents with a variety of congenital and acquired diseases such as hernias, edema, cryptorchidism, cysts, and tumors of the head and neck.",
        HeadDes02:"Our team listens to you and your child. We will work with you to come up with a treatment plan that is tailored to your child's condition. We are constantly engaged in clinical research to develop new diagnostic and therapeutic strategies.",
        HeadDes03:"The Pediatric Surgery Hospital in titwala strives to provide first-class clinical care for children, both outpatient and inpatient. Care is designed to help children recover from mundane to highly complex illnesses that occur from conception through adulthood.",
        HeadDes04:"Our pediatric surgeons and care team work in a learning environment that values children. Working with the Pediatric Surgical Hospital in Titwara and highly qualified pediatric anesthesiologists, these teams approach every procedure with special care for pediatric patients.",
        HeadDes05:"We are committed to continuously educating our patients, families, and colleagues, as well as expanding the knowledge of future leaders in our field.",
        HeadDes06:"We support both the basic and clinical exploration of new diagnostic and therapeutic strategies, and the joy and fulfillment of discovery itself. Furthermore, we aim to be a team of teachers who are full of their own growth, grateful, and rewarded for their health and families. We give you the opportunity to take care of your children and your family.",
        HeadDes07:"At  Pediatric Surgery Hospital in titwala , our team provides care and treatment for infants, children, and adolescents with a wide range of common and complex conditions. In our hospital, we introduce various surgical methods, including the latest minimally invasive surgery, for patients who need surgery. Our team creates an individual care plan for each patient's condition and unique circumstances to ensure the best possible treatment and recovery options.",

    },
    {
        id: 21,
        Slugs:"/neurosurgery",
        SpecialitiesDropName: "Neurosurgery",
        SpecialitiesImage: Neurosurgery,
        HeadDes01: "We are known as the best neurosurgery hospital in titwala. This is beautiful if we are leaders in the diagnosis and treatment of all types of neurological disorders. Our nationally and internationally recognized neurologists and neurosurgeons provide specialized care for diseases and disorders of the brain, spinal cord, and nervous system, including stroke, cerebral aneurysms, brain tumors, and degenerative spinal disease. We also have a special neurosurgical intensive care unit and strive to provide the highest level of care for even the most severe brain injuries.",
        PointHeadTitle01:"Our Specialties  in the best neurosurgery hospital in titwala",
        PointTitle01:"Stroke",
        Point01:"We are an accredited comprehensive stroke center providing the highest level of stroke care 24 hours a day, 365 days a year. We are known for providing advanced interventions to treat stroke before it causes permanent damage to the brain.",
        PointTitle02:"Cerebral Aneurysm",
        Point02:"The Cerebral Aneurysm Program provides expert diagnostics and innovative treatments for cerebral aneurysms and other cerebrovascular diseases such as arteriovenous malformations (AVMs).",
        PointTitle03:"Epilepsy/Movement Disorders",
        Point03:"We offer advanced computer-guided surgical techniques to treat movement disorders such as epilepsy, spasticity, and Parkinson's disease.",
        PointTitle04:"Brain Tumors",
        Point04:"Our team specializes in testing and treating all types of brain tumors. Get the best care from neurosurgeons, neuro-oncologists, neuroradiologists, physical therapists, speech-language pathologists, and other specialists.",
        PointTitle05:"Concussion",
        Point05:"Concussion Management Program recognizes the potentially dangerous long-term effects that traumatic brain injury and concussion can cause.",
        PointTitle06:"Alzheimer's Disease/Amnesia",
        Point06:"Our Memory and Aging Clinic diagnoses and treats people with memory impairment, language impairment, cognitive disorders such as Alzheimer's disease, dementia, and related behavioral disorders.",
        PointTitle07:"Multiple Sclerosis (MS)",
        Point07:"Our Clinic provides comprehensive care for people with MS and related conditions. Our MS clinic provides individualized care and treatment to manage your condition and improve your quality of life.",
        PointTitle08:"Spine Surgery",
        Point08:"We specialize in a wide range of non-surgical, minimally invasive, and conventional surgical techniques to treat problems of the back, neck, and spine.",
    },
    {
        id: 22,
        Slugs:"/physiotherapy",
        SpecialitiesDropName: "Physiotherapy",
        SpecialitiesImage: Physiotherapy,
        HeadDes01: "Our healthcare system is under stress, and you or a loved one may not get the physical therapy they need to stay healthy and mobile while in the hospital. Physiotherapists work with people suffering from physical and medical disabilities and illnesses. Through expert care, they aim to improve physical activity, mobility, and overall quality of life.",
        HeadDes02: "In the physiotherapy clinic in Titwala, they are trained to perform comprehensive assessments, tests, and treatments to address each patient's specific diagnosis and concerns.",
        HeadDes03: "Physiotherapists work with patients to ensure treatment plans are tailored to each patient's unique circumstances. It also monitors patients progress towards treatment goals.",
        HeadDes04: "In the physiotherapy clinic in Titwala, our Physiotherapists help patients improve their mobility, strength, and balance through both individual and group therapy. We support people who want to live independently in the community despite their difficulties. Physiotherapy services for inpatients include assessment and treatment of appropriate physiotherapy during hospitalisation, provision of walking aids, splints, and orthotics, training of patients and carers to support home care, hospital-to-community Includes a discharge plan to facilitate transition into society.",
        PointHeadTitle01: "Our Services at the physiotherapy clinic in Titwala",
        PointHead01: "Our physiotherapists treat many conditions and ailments, such as:",
        Point01: "Respiratory disease due to a sudden serious infection",
        Point02: "Long-lasting respiratory diseases, such as chronic obstructive pulmonary disease (COPD)",
        Point03: "Impairment due to a sudden neurological event",
        PointHead001: "Stroke-resulting dysfunction Parkinson's disease",
        Point001: "Pain or disorder secondary to a sudden musculoskeletal or orthopaedic injury Sprains, Contusions, and fractures",
        Point002: "Hand injuries, surgical pain, and disabilities",
        Point003: "Long-term disorders affecting hands",
        Point004: "Women, such as incontinence",
        Point005: "Infant Developmental Delay/Musculoskeletal Disorders",
        Point006: "Fall Management",
        Point007: "Gait Retraining (retraining the patient to walk)",
    },
    {
        id: 23,
        Slugs:"/dietician",
        SpecialitiesDropName: "Dietician",
        SpecialitiesImage: Dietician,
        HeadDes01:"The Department of Nutrition and Dietetics at Shree Krishna Hospital continues to serve patients with an ever-growing staff based on evidence-based applications while strictly respecting patient wishes. Nutrition and nutrition clinics carry out 'individualised dietary programmes' with health-friendly and sometimes therapeutic elements. In addition to nutritional treatment for inpatients, we also plan menus prescribed by doctors and nutritionists for inpatients at all facilities.",
        HeadDes02:"The goal of the dietitian clinic in Titwala is to educate patients about healthy eating and encourage behavioural change in patients hospitalised with health problems. To this end, we discuss each patient's diet in detail during the initial consultation. For all patients requiring nutritional guidance, body fat, muscle, and water percentages are measured to determine fat distribution. An individualised nutrition plan is then created, taking into account each patient's age, height, gender, and unique physiology. Blood values and patient compliance with the individual plan are monitored regularly. In addition, nutritional plans are developed and documented for patients with certain diseases, such as diabetes, and natural physical changes, such as pregnancy, breastfeeding, childhood, and adolescence.",
        HeadDes03:"There is one key feature of the dietitian clinic in Titwala that allows Shree Hospital to stay ahead of the competition. Our hospital screens all inpatients for malnutrition (a clinical condition associated with inadequate nutrient content and quantity and a lack of energy and nutrients despite body needs), which is making a big positive difference.",
        HeadDes04:"Nutrition and dietary advice and special menu planning are available upon request from private agencies and organisations. Additionally, our nutritionists frequently appear in written and video media to continue to educate and raise awareness among the public.",
    },

];

export default SpecialitiesdropData;
