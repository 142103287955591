////////////////////////////// Our Services /////////////////////////////

import { GiHospital, GiStethoscope, GiMicroscope } from 'react-icons/gi';

import { FaHeartbeat } from 'react-icons/fa';
import { FcBusinesswoman } from 'react-icons/fc';

const DepartmentData = [
    {
        id: 1,
        DepartmentIcon: <GiHospital />,
        DepartmentName: "Gynaecologist And Obstetrician",
        DepartmentSortDes: "Our Gynaecologist uses high-precision diagnostic methods and modern methods of treatment.",
    },
    {
        id: 2,
        DepartmentIcon: <GiStethoscope />,
        DepartmentName: "Child Care",
        DepartmentSortDes: "If you have any complaints about your state of health, start with our therapist and he will help you.",
    },
    {
        id: 3,
        DepartmentIcon: <GiMicroscope />,
        DepartmentName: "ICU Care ( Intensive Care Unit )",
        DepartmentSortDes: "In our laboratory, you can pass a wide range of tests that allow us to put the right diagnosis.",
    },
    {
        id: 4,
        DepartmentIcon: <FaHeartbeat />,
        DepartmentName: "General Surgery",
        DepartmentSortDes: "The timely detection of cardiovascular diseases allows them to be effectively treated.",
    },
    {
        id: 5,
        DepartmentIcon: <FcBusinesswoman />,
        DepartmentName: "Orthopedics",
        DepartmentSortDes: "We have a wide range of highly qualified specialists in various fields of surgery.",
    },
];

export default DepartmentData;